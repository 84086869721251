/* eslint-disable @typescript-eslint/no-unused-vars */
import { v4 as uuidv4 } from "uuid";

import { RoundConfig } from "models";

import ProductionScanner from "helpers/ProductionScanner";

import purefiLogo from "assets/purefi-logo.svg";
import safleLogo from "assets/safle-logo.png";

import { BSC_MAINNET_NETWORK } from "./networks";

const IS_PRODUCTION = ProductionScanner.check();

// array of PRODUCTION rounds
// they are always present among rounds regardless env
const PRODUCTION_ROUND_CONFIGS: RoundConfig[] = [
  {
    id: uuidv4(),
    contractAddress: "0xfdd4eF64dA10fa5809AaBe98a225A4b94E53d8e1",
    networkId: BSC_MAINNET_NETWORK.networkId,
    startDate: "2021-12-09T16:00:00Z",
    endDate: "2021-12-16T23:59:00Z",
    bscDepositTokenAddress: "0xe2a59d5e33c6540e18aaa46bf98917ac3158db0d",
    depositToken: {
      symbol: "UFI",
      icon: purefiLogo,
    },
    rewardToken: {
      symbol: "SAFLE",
      icon: safleLogo,
    },
    link: "https://purefi-protocol.medium.com/safle-purefi-round-starting-on-dec-9-2021-c89d1630896f",
  },
];

// array of NON-PRODUCTION rounds
// they won't appear in production if REACT_APP_IS_PRODUCTION === 'true'
// for details see how github action workflows are configred
const OTHER_ROUND_CONFIGS: RoundConfig[] = [];

const ROUNDS: RoundConfig[] = PRODUCTION_ROUND_CONFIGS;

if (!IS_PRODUCTION && OTHER_ROUND_CONFIGS.length > 0) {
  ROUNDS.push(...OTHER_ROUND_CONFIGS);
}

export default ROUNDS;
